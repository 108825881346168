import {inject, observer} from "mobx-react";
import * as React from "react";

import RemovableMessage from "@components/RemovableMessage";
import {recaptchaToken} from "@consts/index";
import {
    ErrorCodes,
    IQuestionAddRequest,
    SetQuestionAdd,
    SuccessCodes,
} from "@libs/api";
import {IRootStore} from "@store/index";
import {observable} from "mobx";
import * as Recaptcha from "react-recaptcha";

@inject("store")
@observer
class QuestionAdd extends React.Component<IQuestionAddProps, {}> {

    @observable public messageOk: string = "";
    @observable public messageError: string = "";
    private refTitle = React.createRef<HTMLInputElement>();
    private refCategory = React.createRef<HTMLSelectElement>();
    private refQuestion = React.createRef<HTMLTextAreaElement>();
    private refSubmit = React.createRef<HTMLButtonElement>();
    private recaptchaInstance: Recaptcha | null = null;

    public render() {
        if (this.props.store && !this.props.store.ctf.isLoggedIn) {
            return null;
        }
        const categories: Array<[string, string]> = [
            ["General", "General"],
            ["Technical", "Technical"],
            ["RE", "RE"],
            ["PWN", "PWN"],
            ["WEB", "WEB"],
            ["MISC", "MISC"],
            ["CRYPTO", "CRYPTO"],
            ["PPC", "PPC"],
            ["STEGANO", "STEGANO"],
            ["FORE", "FORE"],
        ];

        return (
            <div className={"questionForm"}>
                {this.messageError && <RemovableMessage type={"error"} time={3000}>{this.messageError}</RemovableMessage>}
                {this.messageOk && <RemovableMessage type={"success"} time={3000}>{this.messageOk}</RemovableMessage>}

                <h1>Ask us something</h1>

                <div className={"form"}>
                    <div className={"form-group"}>
                        <label htmlFor={"questionTitle"}>Title</label>
                        <input type={"text"} name={"questionTitle"} placeholder={"TITLE"} id={"questionTitle"} ref={this.refTitle} />
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor={"questionCategory"}>Category</label>
                        <select ref={this.refCategory} id={"questionCategory"}>
                            {categories.map(([value, name]) => (
                                <option key={value} value={value}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <textarea ref={this.refQuestion} rows={10} placeholder={"Where here your question (long 0x1337)"} />
                    <button ref={this.refSubmit} onClick={this.onSubmit} type={"submit"}>Send</button>
                </div>

                <Recaptcha
                    ref={(e) => this.recaptchaInstance = e}
                    sitekey={recaptchaToken}
                    size="invisible"
                    render={"explicit"}
                    onloadCallback={() => null}
                    verifyCallback={this.verifyCaptcha}
                    theme={"dark"}
                />
            </div>
        );
    }

    private onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.recaptchaInstance && this.recaptchaInstance.execute();
    }

    private verifyCaptcha = (captchaToken: string) => {
        const value = (this.refQuestion.current && this.refQuestion.current.value) || "";
        if (!value) {
            return;
        }
        const form: IQuestionAddRequest = {
            title: (this.refTitle.current && this.refTitle.current.value) || "",
            category: (this.refCategory.current && this.refCategory.current.value) || "",
            question: value,
            captcha: captchaToken,
        };

        this.refSubmit.current && this.refSubmit.current.setAttribute("disabled", "disabled");
        (async () => {
            this.messageOk = "";
            this.messageError = "";
            let err = null;
            try {
                const err2 = await SetQuestionAdd(form);
                if (err2) {
                    err = ErrorCodes.toHumanMessage(err2);
                }
            } catch (e) {
                console.error("send err", e);
                err = String(e);
            }
            if (err === null) {
                this.messageError = "";
                this.messageOk = SuccessCodes.toHumanMessage(SuccessCodes.question_added);
            } else {
                this.messageOk = "";
                this.messageError = err;
            }

            if (this.refQuestion.current) { this.refQuestion.current.value = ""; }

        })().finally(() => {
            this.refSubmit.current && this.refSubmit.current.removeAttribute("disabled");
        });
    }
}


interface IQuestionAddProps {
    store?: IRootStore;
}


export default QuestionAdd;
