import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import * as React from "react";

import {IRootStore} from "@store/index";

import AnnouncementElement from "@components/AnnouncementElement";
import Footer from "@components/Footer";
import Loader from "@components/Loader";
import Modal from "@components/Modal";
import QuestionAdd from "@components/QuestionAdd";
import QuestionElement from "@components/QuestionElement";

import "@styles/announcements.scss";

@inject("store")
@observer
export class AnnouncementsPage extends React.Component<IAnnouncementsPageProps, {}> {
    @observable public questionIsHidden: boolean = false;
    @observable public questionShowForm: boolean = false;

    public async componentDidMount() {
        this.props.store.ctf.fetchAnnouncements().then(() => {
            this.props.store.ctf.setSeenAnnouncements();
        });
        this.props.store.ctf.fetchQuestions();
    }

    public render( ) {
        return (
            <div className={"page announcements"}>
                {this.props.store && this.props.store.ctf.isLoggedIn && <>
                    <div className={"inner"}>
                        <div className={"top"}>
                            <button onClick={this.onHide} className={"hide" + (this.questionIsHidden ? "" : " active")}>Hide</button>
                            <h1 className={"mainTitle clickable"}>Questions</h1>
                            <button onClick={this.onAdd} className={"addQuestion"}>Ask</button>
                        </div>

                        {this.props.store.ctf.questionsState === "pending" && <Loader text={"Loading questions"}/>}
                        {this.props.store.ctf.questionsState === "error" && <Loader text={"Error during loading questions"}/>}

                        {this.props.store.ctf.announcementsState === "done" && !this.questionIsHidden && (<div className={"list"}>
                            {this.props.store && Array.from(this.props.store.ctf.questions.values()).map((row, index) => (
                                <QuestionElement key={row.id} info={row} index={index}/>
                            ))}
                        </div>)}
                    </div>
                    <Modal closable={true} active={this.questionShowForm} onCloseButtonClick={this.hideForm} onBackgroundClick={this.hideForm}>
                        <QuestionAdd />
                    </Modal>
                </>}
                <div className={"inner"}>
                    <h1 className={"mainTitle"}>News</h1>

                    {this.props.store.ctf.announcementsState === "pending" && <Loader text={"Loading news"} />}
                    {this.props.store.ctf.announcementsState === "error" && <Loader text={"Error during loading news"} />}
                    {this.props.store.ctf.announcementsState === "done" && (<div className={"list"}>
                        {this.props.store && Array.from(this.props.store.ctf.announcements.values()).map((row, index) => (
                            <AnnouncementElement key={row.id} info={row} index={index} />
                        ))}
                    </div>)}
                    <Footer sticky={true} />
                </div>

            </div>
        );
    }

    private onHide = ( ) => {
        this.questionIsHidden = !this.questionIsHidden;
    }

    private onAdd = ( ) => {
        this.questionShowForm = !this.questionShowForm;
    }
    
    private hideForm = ( ) => {
        this.questionShowForm = false;
    }
}

interface IAnnouncementsPageProps {
    store: IRootStore;
}
