import {inject, observer} from "mobx-react";
import {RouterStore} from "mobx-react-router";
import * as React from "react";

import {
    AnnouncementsPage,
    ChallengesPage,
    HomePage,
    LoginPage,
    NotFoundPage,
    RecoverInitPage,
    RecoverSetPasswordPage,
    RegisterPage,
    RulesPage,
    FaqPage,
    ScoreboardPage,
    SettingsPage,
    TeamPage,
    TeamsPage,
    UnavailablePage,
} from "../pages";

const Container: React.FunctionComponent<IContainerProps> = ( { routing}: IContainerProps ) => {
    if ( !routing ) {
        return null;
    }

    const { location, push } = routing;

    const componentDict: { [key: string]: any; } = {
        "/": HomePage,
        "/challenges": ChallengesPage,
        "/scoreboard": ScoreboardPage,
        // "/challenges": UnavailablePage,
        // "/scoreboard": UnavailablePage,
        "/rules": RulesPage,
        "/faq": FaqPage,
        "/teams": TeamsPage,
        "/news": AnnouncementsPage,
        "/settings": SettingsPage,
        "/login": LoginPage,
        "/register": RegisterPage,
        "/recover/init": RecoverInitPage,
        "/recover/set_password": RecoverSetPasswordPage,
        "/404": NotFoundPage,
    };

    const teamRegex = new RegExp(`^\/team\/(\\d+)$`);

    if ( teamRegex.test(location.pathname) ) {
        const matches = teamRegex.exec( location.pathname );

        if ( matches ) {
            return <TeamPage id={parseInt(matches[ 1 ], 10)} />;
        }
    }

    const challangeRegex = new RegExp(`^\/challenges\/(\\d+)$`);

    if ( challangeRegex.test(location.pathname) ) {
        const matches = challangeRegex.exec( location.pathname );

        if ( matches ) {
            return <ChallengesPage task_id={parseInt(matches[ 1 ], 10)} />;
        }
    }

    let ComponentTag = componentDict[location.pathname];

    if (!ComponentTag) {
        push("/404");
        ComponentTag = componentDict["/404"];
    }

    return <ComponentTag />;

};

interface IContainerProps {
    routing?: RouterStore;
}

export default inject("routing")(observer(Container));
