import * as React from "react";

import {formatDate} from "@libs/date";
import {IAnnouncement, IQuestion} from "@store/CtfStore";
import ReactMarkdown from "react-markdown";

const QuestionElement: React.FunctionComponent<IQuestionElementProps> = ( { info, index }) => {
    const [collapsed, setCollapsed] = React.useState<boolean>(true);

    const changeVisible = React.useCallback( (e: React.MouseEvent) => {
        e.preventDefault();

        setCollapsed(!collapsed);
    }, [collapsed]);

    return (
        <div className={"question"}>
            <header onClick={changeVisible}>
                <h2>Question: [{info.api.category}] {info.api.title}</h2>
                {info.isNew() && <span className={"label"}>New</span>}
                <div className={"date"}>
                    {formatDate(info.api.created_at)}
                </div>
            </header>

            {!collapsed && <div className={"messages"}>
                <div className={"message"}>
                    <h5>Question</h5>

                    <div className={"body"}>
                        <ReactMarkdown source={info.api.question} escapeHtml={true} />
                    </div>
                </div>

                <div className={"message"}>
                    <h5>Answer</h5>

                    <div className={"body"}>
                        {!!info.api.answer && info.api.answer.length && <ReactMarkdown source={info.api.answer} escapeHtml={true} />}
                        {!info.api.answer || !info.api.answer.length && "Nobody answered your question"}
                    </div>
                </div>
            </div>}
        </div>
    );
};

interface IQuestionElementProps {
    info: IQuestion;
    index: number;
}

export default QuestionElement;
