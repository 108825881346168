

export function formatDate(val: Date): string {
    return val.toLocaleString("en-GB", { timeZone: "UTC" }) + " UTC";
}

function ordinalSuffixOf(i: number): string {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

function numberToMonth(i: number): string {
    const a: any = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    };
    return a[i + 1];
}

function numberToDay(i: number): string {
    const a: any = {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
    };
    return a[i];
}

// Friday, 20th of December 20:00 UTC
export function formatToCustomJctf(val: Date) {
    return numberToDay(val.getUTCDay()) + ", " + ordinalSuffixOf(val.getUTCDate()) + " of " + numberToMonth(val.getUTCMonth()) + " " + val.getUTCHours().toString().padStart(2, "0") + ":" + val.getUTCMinutes().toString().padStart(2, "0") + " UTC";
}