import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import {RouterStore} from "mobx-react-router";
import * as React from "react";

import Footer from "@components/Footer";

import {IRootStore} from "@store/index";

import {ErrorCodes, IRecoverSetPasswordRequest, SetRecoverSetPassword, SuccessCodes} from "@libs/api";
import http from "@libs/http";

@inject("store", "routing")
@observer
export class RecoverSetPasswordPage extends React.Component<IRecoverSetPasswordPageProps, {}> {

    @observable public errorMessage: string = "";
    @observable public successMessage: string = "";
    private refNewPassword = React.createRef<HTMLInputElement>();
    private refSubmit = React.createRef<HTMLButtonElement>();

    public render( ) {
        return (
            <div className={"page recover"}>
                <div className={"inner"}>
                    <h1 className={"mainTitle"}>Reset Password</h1>

                    {this.errorMessage && this.errorMessage.length && <div className={"errorMessage"}>{this.errorMessage}</div>}
                    {this.successMessage && this.successMessage.length && <div className={"successMessage"}>{this.successMessage}</div>}

                    <form onSubmit={this.formSubmit}>
                        <div className={"form-group"}>
                            <label htmlFor={"newPassword"}>new password</label>
                            <input type={"password"} name={"newPassword"} placeholder={"NEW PASSWORD"} id={"newPassword"} ref={this.refNewPassword} />
                        </div>

                        <button className={"submitButton"} ref={this.refSubmit} type={"submit"}>reset password</button>
                    </form>

                    <Footer sticky={true} />
                </div>
            </div>
        );
    }

    private formSubmit = ( e: React.FormEvent<HTMLFormElement> ) => {
        e.preventDefault();

        const form: IRecoverSetPasswordRequest = {
            password: (this.refNewPassword.current && this.refNewPassword.current.value) || "",
            token: http.locationGetParam(this.props.routing.location.search, "token"),
        };

        this.refSubmit.current && this.refSubmit.current.setAttribute("disabled", "disabled");
        (async () => {
            let err = null;
            try {
                const err2 = await SetRecoverSetPassword(form);
                if (err2) {
                    err = ErrorCodes.toHumanMessage(err2);
                }
            } catch (e) {
                err = String(e);
            }
            if (err !== null) {
                this.errorMessage = String(err);
                this.successMessage = "";

                return;
            }

            this.errorMessage = "";
            this.successMessage = SuccessCodes.toHumanMessage(SuccessCodes.recover_password_changed);
            // TODO: redirect to /login

            if (this.refNewPassword.current) { this.refNewPassword.current.value = ""; }

        })().finally(() => {
            this.refSubmit.current && this.refSubmit.current.removeAttribute("disabled");
        });
    }
}

interface IRecoverSetPasswordPageProps {
    store: IRootStore;
    routing: RouterStore;
}
